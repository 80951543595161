import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Home from '../components/home';

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Layout>
      <Home />
    </Layout>
  </>
);
export default IndexPage;
