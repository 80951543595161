import React from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { Row, Col } from 'antd';

import ImageHero from './image-components/hero/hero';
import HomeVocation from './image-components/home/homeVocation';
import Styles from './home.module.css';

const Home = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        <ImageHero />
        <div className={Styles.main_copy}>
          <Row>
            <Col className={Styles.copy_left} md={16} sm={24}>
              <p className={Styles.copy_left_heading}>
                JESUS TAUGHT ALL THE TIME. LET'S LEARN.
              </p>
              <p className={Styles.copy_body}>
                There were moments throughout the life of Jesus when, after
                teaching the large crowds, he would withdraw with his disciples
                and teach them further, deeper, in more detail. There were
                things Jesus taught his disciples that would have been
                impossible to share with the crowds. And so it is with us.
              </p>
              <p className={Styles.copy_body}>
                There are moments where our Lord takes us away from the crowds
                to teach and train us in ways that can only happen in smaller,
                more intentional settings. And it’s in those settings that we
                find ourselves growing, learning, maturing. It’s in these
                moments that his Spirit imparts not just his knowledge, but his
                heart. Advance is our opportunity to go away with experienced
                guides, mature in the ways of Jesus, to learn more deeply,
                intentionally, intimately, so that we may find ourselves changed
                by his Presence.
              </p>
            </Col>
            <Col className={Styles.copy_right} md={8} sm={24}>
              <p className={Styles.copy_right_heading}>SPRING ADVANCES</p>
              <div className={Styles.copy_right_list}>
                <div className={Styles.course_title_wrapper}>
                  <Link to="/vocation/" className={Styles.course_title}>
                    Vocation
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </motion.div>
      <div className={Styles.courses_container}>
        <HomeVocation />
      </div>
    </>
  );
};

export default Home;
