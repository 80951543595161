import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Styles from './course.module.css';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const HomeVocation = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "home-vocation.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage
      Tag="section"
      className={Styles.img}
      fluid={data.placeholderImage.childImageSharp.fluid}
    >
      <Link to="/vocation/" className={Styles.title}>
        VOCATION
      </Link>
      <div className={Styles.overlay}>
        <div className={Styles.overlay_container}>
          <div className={Styles.description}>
            <div>Loving Our Vocations via</div>
            <div>the Love of God.</div>
          </div>
          <div className={Styles.button}>
            <Link to="/signup/" className={Styles.button_text}>
              SIGN UP
            </Link>
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default HomeVocation;
